<template>
    <v-container class="page-container" fill-height fluid grid-list-xl
    >
        <v-layout class="self-care-layout" wrap id="configuration">
            <!-- Header title -->
            <h1 class="page-title text-xs-left mb-3 w-100">{{pageTitle}}</h1>

            <!-- Add interview button -->
            <div class="flex-display flex-row flex-justify-end mb-3 w-100">
                <button class="btn add-new" @click="showAddVideoDialog('add',null)">
                    <i class="v-icon mdi mdi-plus"></i> {{addButtonText}}
                </button>
            </div>

            <!-- Interviews list -->
            <template v-if="isReady">
                <div class="item-container"
                     v-for="(interview, index) in interviews"
                     :key="interview.id"
                >
                    <p class="item-text w-100">{{interview.name}} ({{interview.key}})</p>
                    <div class="item-actions">
                        <button class="btn-edit" @click="showAddVideoDialog('edit', index)">Edit</button>
                        <!--<button class="btn-delete ml-2" @click="showDeleteVideoDialog(interview)">Delete</button>-->
                    </div>
                </div>
            </template>

            <!-- Add interview dialog -->
            <add-interview v-if="addVideoDialog"
                       :visible="addVideoDialog"
                       :actionType="actionType"
                       :therapists="therapists"
                       :screens="screens"
                       :total="interviews.length"
                       :value="selectedInterview"
                       v-on:submitInterview="submitInterview"
                       v-on:hide="hideAddVideoDialog"
            >
            </add-interview>
        </v-layout>

    </v-container>
</template>

<script>
    import AddInterview from "../components/configuration/AddInterview";
    export default {
        name: 'SelfCarePGInterviews',
        components: {
            AddInterview
        },
        data: () => ({
            pageTitle: 'PG Interview',
            addButtonText: 'New Video',
            isReady: true,
            addVideoDialog: false,
            deleteVideoDialog: false,
            actionType: '',
            interviews: [],
            therapists: [],
            screens: [],
            selectedIndex: null,
            selectedInterview: {
                user_id: null,
                key: '',
                name: '',
                duration_count: '',
                duration_unit: '',
                url: '',
                thumbnail_url: '',
                web_url: '',
                web_thumbnail_url: '',
                order: [],
                visibility: [],
                status: ''
            },
        }),
        created() {
            this.getSelfCareInterviews();
        },
        methods: {
            getSelfCareInterviews() {
                let loader = this.$loading.show();
                this.$http.get(this.url + 'interviews').then(response => {
                    this.interviews = response.data.interviews;
                    this.therapists = response.data.therapists;
                    this.screens = response.data.screens;
                    loader.hide();
                }).catch(error => {
                    this.$toastr('error', error.response.data.message, 'Error');
                    loader.hide();
                })
            },
            submitInterview(interview) {
                let loader = this.$loading.show();
                this.$http.put(this.url + 'interview', {
                    ...interview,
                    order: JSON.stringify(interview.order),
                    visibility: JSON.stringify(interview.visibility)
                }).then(response => {
                    this.selectedIndex === null ?
                        this.interviews.push(response.data.interview) :
                        this.interviews[this.selectedIndex] = response.data.interview;
                    this.hideAddVideoDialog();
                    this.$toastr('success', response.data.message, '');
                    loader.hide();
                }).catch(error => {
                    this.$toastr('error', error.response.data.message, '');
                    loader.hide();
                });
            },
            showAddVideoDialog(action, index) {
                this.addVideoDialog = true;
                this.actionType = action;
                this.selectedIndex = index;
                if (this.selectedIndex === null) {
                    this.resetSelectedInterview();
                } else {
                    this.selectedInterview = this.interviews[index];
                }
            },
            hideAddVideoDialog() {
                this.resetSelectedInterview();
                this.addVideoDialog = false;
            },
            resetSelectedInterview() {
                this.selectedIndex = null;
                this.selectedInterview = {
                    user_id: null,
                    key: '',
                    name: '',
                    duration_count: '',
                    duration_unit: '',
                    url: '',
                    thumbnail_url: '',
                    web_url: '',
                    web_thumbnail_url: '',
                    order: [],
                    visibility: [],
                    status: ''
                }
            }
        }
    }
</script>
<style scoped>

</style>
